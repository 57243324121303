<template>
  <VContainer
    fill-height
    class="align-content-center"
  >
    <VRow>
      <VCol>
        <div
          id="g_id_signin"
          ref="g_id_signin"
          style="width: 280px; margin: 0 auto;"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol class="d-flex justify-center flex-shrink-0">
        <div class="tt-text-title-2 tt-light-mono-46--text my-10">
          ИЛИ
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol class="d-flex justify-center">
        <TTBtn
          large
          rounded
          @click="keycloakInit"
        >
          Войти через Keycloak
        </TTBtn>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
export default {
  name: 'SignIn',

  async mounted() {
    /* global google */
    const GsiButtonConfiguration = {
      type: 'standard',
      theme: 'filled_blue',
      size: 'large',
      text: 'signin_with',
      shape: 'pill',
      logo_alignment: 'left',
      width: 280,
      // locale: '',
    };

    google.accounts.id.renderButton(this.$refs.g_id_signin, GsiButtonConfiguration);

    await this.checkKeycloakAndLogin();
  },

  methods: {
    // TODO: временное решение, пока есть отдельная кнопка для входа
    // Проверяем что мы вернулись из keycloak, если да, то вызываем инициализацию keycloak
    async checkKeycloakAndLogin() {
      if (!window.location.hash) return;
      await this.keycloakInit();
    },
    async keycloakInit() {
      const authenticated = await this.$di.api.KeycloakAuth.init();

      if (!authenticated) {
        await this.$di.api.KeycloakAuth.login();
      }

      const {
        keycloak: { idToken, token },
      } = this.$di.api.KeycloakAuth;

      // сохраняем токен Keycloak в куки
      this.$di.api.KeycloakToken.set(token);

      // полученный ранее idToken отправляем в sysadmin для получения jwt
      const data = { idToken };

      try {
        const response = await this.$di.api.Sysadmin.oauthSignInV2({ data });

        // сохраняем токен AccessToken в куки
        this.$di.api.AccessToken.set(response.jwt);

        await this.$router.push(this.$route.query.redirect || '/app/home');
      } catch (err) {
        this.$di.notify.errorHandler(err);
      }
    },
  },
};
</script>
